.root {
   position: relative;
   z-index: 0;
}

.root:before {
   content: "";
   background: linear-gradient(
      45deg,
      #ff0000,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff0000
   );
   position: absolute;
   top: -2px;
   left: -2px;
   background-size: 400%;
   z-index: -1;
   filter: blur(5px);
   width: calc(100% + 4px);
   height: calc(100% + 4px);
   animation: glowing 20s linear infinite;
   opacity: 0;
   transition: opacity 0.3s ease-in-out;
   border-radius: 10px;
}

.root:hover:before {
   opacity: 1;
}

.root:after {
   z-index: -1;
   content: "";
   position: absolute;
   width: 100%;
   height: 100%;
   background: #111;
   left: 0;
   top: 0;
   border-radius: 10px;
}

@keyframes glowing {
   0% {
      background-position: 0 0;
   }
   50% {
      background-position: 400% 0;
   }
   100% {
      background-position: 0 0;
   }
}
