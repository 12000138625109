@import "normalize";

.header {
    border-bottom: 1px solid #777;
    background: #020202;
    color: white;
    padding: 20px 0;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
    }

    &__wrapper-logo-message {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &-logo {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }

    &-message {
        p {
            @extend %font-common-regular;
            padding: 0;
            margin: 0;
            text-align: center;
            font-size: 13px;
        }
    }

    &__wrapper-search-actions {
        display: flex;
    }

    &-search {
        &__wrapper {
            width: 288px;
            margin-right: 60px;
            margin-left: 20px;
            transition: all 0.15s ease-in-out;

            &__button {
                display: flex;
                align-items: center;
                text-align: left;
                width: 100%;
                height: 30px;
                cursor: pointer;

                @include border-background;
                border-radius: 5px;

                .icon-search {
                    @include icon-btn;
                    margin-right: 10px;
                    margin-left: 5px;
                }

                .icon-press {
                    @include icon-btn;
                }

                .icon-close {
                    @include icon-btn;
                    margin-right: 5px;
                    display: none;
                }

                input {
                    outline: none;
                    border: none;
                    background-image: none;
                    background-color: transparent;
                    box-shadow: none;
                    @extend %font-common-regular;
                    flex: 1;
                }

                &--bin {
                    border: 1px solid #ff0000;
                }
            }

            &:focus-within {
                width: 40vw;

                .icon-press {
                    display: none;
                }

                .icon-close {
                    display: flex;
                }
            }
        }
    }

    &-actions {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        gap: 40px;
    }

    &-button {
        @include icon-btn;
        cursor: pointer;

        &:hover {
            path {
                fill: aqua;
            }
        }
    }

    .search--btn {
        display: none;
    }

    // @media (max-width: 750px) {
    //     &-search {
    //         &__wrapper {
    //             display: flex;
    //         }
    //     }

    //     .search--btn {
    //         display: flex;
    //     }
    // }

    @media (max-width: $mobile-view-width) {
        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 20px;
        }
    }
}
