@import "fonts";
@import "global-commons";
@import "header";
@import "topbar";
@import "note-container";
@import "normalize";
@import "tags";
@import "md-editor";
@import "page-loading";

.body__wrapper {
}

.main {
    $content-width: calc(100vw - 8vw);

    &__wrapper {
        width: $content-width;
        height: 100%;
        margin: 40px auto;
    }

    .notes-content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, auto));
        justify-content: center;
        grid-column-gap: 10px;
        padding: 40px 0;
    }
}
